<template>
	<div class="content">
		<div class="tilte">
			<div class="left">
				<div></div>
				<span>{{ $t('button.chakanwuliu') }}</span>
			</div>
			<el-input v-model="orderNumber" :placeholder="$t('mine.pepol.plece')" @change="getData">
			</el-input>
		</div>
		<div class="timeline_box">
			<el-timeline>
				<el-timeline-item v-for="(activity, index) in activities" :key="index">
					<div slot="dot" v-if="index == 0">
						<div class="first_dot">
							<img src="@/assets/img/timeline_icon.png">
						</div>
					</div>
					<div class="line_text" :class="index == 0 ? 'active' : ''">
						<div class="title">{{ activity.content }}</div>
						<div class="timestamp">{{ activity.createTime }}</div>
					</div>
				</el-timeline-item>
			</el-timeline>
		</div>
	</div>
</template>
<script>
	import {
		listdsnChuru
	} from '@/api/main.js'
	export default {
		data() {
			return {
				orderNumber: '',
				activities: [],
			};
		},
		mounted() {
			this.orderNumber = this.$route.query.orderNumber
			if(this.orderNumber){
				this.getData()
			}
		},
		methods: {
			getData() {
				if(!this.orderNumber){
					this.showMessage('请输入物流单号')
					return
				}
				listdsnChuru({
					orderNumber: this.orderNumber
				}).then(res => {
					if (res.code == 0) {
						this.activities = res.rows
					} else {
						this.showMessage(res)
					}
				})
			}
		}
	};
</script>
<style lang="scss" scoped>
	.content {
		width: 880px;

		.tilte {

			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 9px 0px;
			padding-top: 0px;
			margin-bottom: 20px;

			.left {
				display: flex;

				div {
					width: 2px;
					height: 24px;
					background: #333333;
					margin-right: 10px;
				}

				span {
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
				}
			}

			.el-input {
				width: 18.75rem;
			}
		}

		.timeline_box {
			background: #F9F9F9;
			padding: 50px 30px;

			.first_dot {
				width: 22px;
				transform: translate(-25%, -25%);

				img {
					max-width: 100%;
					height: auto;
				}
			}

			.line_text {
				padding-bottom: 50px;
				font-size: 14px;
				color: #777;
				max-width: 300px;

				&.active {
					color: #333;
				}

				.timestamp {
					padding-top: 10px;
					font-size: 13px;
				}
			}
		}
	}
</style>